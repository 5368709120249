import { useRowHeight } from './useRowHeight'

export const useFullLinesOverlayData = (rowCount:number, fullLines:Array<number>) => {

	const { rowHeight, boardRef } = useRowHeight(rowCount)
	
	const lines = Array(rowCount)
		.fill(true)
		.map((_, index) => {
			return fullLines.includes(index);
		})

	return { rowHeight, boardRef, lines }
}