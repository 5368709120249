import React from "react";
import styled from "styled-components";

interface Props {
  lines: Array<boolean>;
  lineHeight: number;
}

export const FullLinesOverlay = (props: Props) => {
  return (
    <Container>
      {props.lines.map((full, index) => {
        return (
          <Line $full={full} $height={props.lineHeight} key={index}>
            bond created together
          </Line>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  justify-items: center;
`;

// TODO correct typeface
const Line = styled.div<{ $full: boolean; $height: number }>`
  background: ${(props) =>
    props.$full ? "rgba(225, 0, 15, 0.75)" : "transparent"};
  height: ${(props) => props.$height}px;
  width: ${(props) => (props.$full ? "100%" : "0%")};

  display: grid;
  place-content: center;

  color: #fff;
  font-size: ${(props) => props.$height / 2.2}px;

  font-family: SegoeUIBold;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;

  transition:
    width 1s,
    background 1s;
`;
