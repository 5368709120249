import * as Logic from "./logic.ts";
import * as Data from "./data.ts";
import { RandomGenerator } from "./RandomGenerator.ts";
import { BoardSyncManager } from "./BoardSyncManager.ts";

interface Piece {
  x: number;
  y: number;
  rotation: number;
  tetromino: Logic.Tetromino;
}

interface PieceState {
  x: number;
  y: number;
  rotation: number;
  tetrominoType: Data.TetrominoType;
}

type RotationDirection = "clockwise" | "counterclockwise";
type ActionCallback = (action: Action, gameOver: boolean) => void;
type BlockDataUpdateCallback = (blockData: Logic.BlockData) => void;

export type ActionType =
  | "move"
  | "rotate"
  | "hardDrop"
  | "gameStep"
  | "removeLines"
  | "setPiece"
  | "setBoard";
type MoveDirection = "left" | "right" | "down";

interface BaseAction {
  type: ActionType;
  preventSync?: boolean;
  force?: boolean;
}

interface MoveAction extends BaseAction {
  type: "move";
  direction: MoveDirection;
}

interface RotateAction extends BaseAction {
  type: "rotate";
  direction: RotationDirection;
}

interface HardDropAction extends BaseAction {
  type: "hardDrop";
}

interface GameStepAction extends BaseAction {
  type: "gameStep";
}

interface RemoveLinesAction extends BaseAction {
  type: "removeLines";
  lines: Array<number>;
}

export interface SetPieceAction extends BaseAction, PieceState {
  type: "setPiece";
}

interface SetBoardAction extends BaseAction {
  type: "setBoard";
  board: Logic.BlockData;
}

type Action =
  | MoveAction
  | RotateAction
  | HardDropAction
  | GameStepAction
  | RemoveLinesAction
  | SetPieceAction
  | SetBoardAction;

export type NetworkAction = RemoveLinesAction | SetBoardAction | SetPieceAction;

export interface NetworkDriver {
  send: (action: NetworkAction) => Promise<boolean>;
  registerReceiver: (board: GameBoard, actionTypes?: Array<ActionType>) => void;
}

export interface ActionHistory {
  actions: Array<{
    timestamp: number;
    action: Action;
  }>;
}

interface Options {
  initalPiece?: Data.TetrominoType;
  actionCallback?: ActionCallback;
  singlePlayerFullLinesCallback?: SinglePlayerFullLinesCallback;
}

export type SinglePlayerFullLinesCallback = (
  lines: Array<number>,
  removeLines: () => void,
) => void;

export class GameBoard {
  private board: Logic.BlockData;
  private piece: Piece;
  private gameOver = false;
  private actionCallbacks: Array<ActionCallback> = [];
  private blockDataUpdateCallbacks: Array<BlockDataUpdateCallback> = [];

  private gameStartTimestamp: number; // only used for prototyping
  private actionHistory: ActionHistory; // only used for prototyping

  private rng = new RandomGenerator();

  public syncManager?: BoardSyncManager;
  public transmitPieceCallback: (action: SetPieceAction) => void;
  public transmitBoardCallback: (action: SetBoardAction) => void;
  private actionLock: boolean = false;

  private singlePlayerFullLinesCallback?: SinglePlayerFullLinesCallback;

  constructor(initialBoard: Logic.BlockData, options?: Options) {
    this.board = {
      width: initialBoard.width,
      data: [...initialBoard.data],
    };
    this.resetPiece(options?.initalPiece);

    if (options?.actionCallback) {
      this.actionCallbacks.push(options.actionCallback);
    }

    if (options?.singlePlayerFullLinesCallback) {
      this.singlePlayerFullLinesCallback =
        options.singlePlayerFullLinesCallback;
    }

    this.gameStartTimestamp = Date.now();
    this.actionHistory = { actions: [] };
  }

  private resetPiece(type?: Data.TetrominoType) {
    let tetromino: Logic.Tetromino;
    if (type) {
      tetromino = Data.Tetrominos[type];
    } else {
      tetromino = Data.Tetrominos[this.rng.nextTetromino()];
    }

    this.piece = {
      y: 0,
      x: Math.round(this.board.width / 2 - tetromino.rotation[0].width / 2),
      rotation: 0,
      tetromino,
    };

    const collision = Logic.intersects(
      this.board,
      this.pieceShape,
      this.piece.x,
      this.piece.y,
    );
    return collision;
  }

  public async action(action: Action) {
    // TODO ignore action if game over
    if (!this.actionLock || action.force === true) {
      this.actionHistory.actions.push({
        timestamp: Date.now() - this.gameStartTimestamp,
        action,
      });

      if (action.type === "move") {
        if (action.direction === "down") {
          this.gameStep();
        } else {
          const direction = action.direction === "left" ? -1 : 1;
          this.move(direction, 0);
        }
      }
      if (action.type === "rotate") {
        this.rotate(action.direction);
      }
      if (action.type === "hardDrop") {
        this.hardDrop();
      }
      if (action.type === "gameStep") {
        this.gameStep();
      }
      if (action.type === "removeLines") {
        this.board = Logic.removeLines(this.board, action.lines);
      }
      if (action.type === "setPiece") {
        this.piece = {
          x: action.x,
          y: action.y,
          rotation: action.rotation,
          tetromino: Data.Tetrominos[action.tetrominoType],
        };
      }
      if (action.type === "setBoard") {
        const boardData: Logic.BlockData = {
          width: action.board.width,
          data: [...action.board.data],
        };

        if (this.syncManager && !action.preventSync) {
          // syncManager is registered therefore its necessary
          // to use it instead of just directly setting board data
          this.actionLock = true; // prevent other user actions until synced
          this.board = boardData; // temporarily apply boardData while awating syncedBoardData - otherwise it looks like the current piece just disappears!
          const syncedBoardData = await this.syncManager.setBoard(boardData);
          this.board = syncedBoardData; // apply synced data

          this.actionLock = false; // allow user interactions
        } else {
          // single or dual single player game

          this.board = boardData;

          if (this.transmitBoardCallback) {
            this.transmitBoardCallback({
              type: "setBoard",
              action: this.board,
            });
          }
        }
      }

      if (this.syncManager && !action.preventSync) {
        const setPieceTriggers: Array<ActionType> = [
          "move",
          "rotate",
          "hardDrop",
          "gameStep",
          "setPiece",
        ];
        if (setPieceTriggers.includes(action.type)) {
          this.syncManager.setPiece({
            type: "setPiece",
            ...this.pieceState,
          });
        }
      } else {
        // single or dual single player game
        if (this.transmitPieceCallback) {
          this.transmitPieceCallback({
            type: "setPiece",
            action: this.pieceState,
          });
        }
      }

      this.actionCallbacks.forEach((callback) => {
        callback(action, this.gameOver);
        /*
				  if(this.gameOver){
					// dev/prototyping output only used to retrieve data for GameplayPlayback:
					console.log(this.actionHistory)
				  }
				*/
      });

      this.blockDataUpdateCallbacks.forEach((callback) => {
        callback(this.currentBlockData);
      });
    } else {
      // console.log('ignoring action because of actionLock', action)
    }
  }

  private move(deltaX: number, deltaY: number): boolean {
    const checkX = this.piece.x + deltaX;
    const checkY = this.piece.y + deltaY;

    const blocked = Logic.intersects(
      this.board,
      this.pieceShape,
      checkX,
      checkY,
    );

    if (!blocked) {
      this.piece.x = checkX;
      this.piece.y = checkY;
      return true;
    } else {
      return false;
    }
  }

  private hardDrop() {
    while (this.move(0, 1)) {}
    this.gameStep(false);
  }

  private rotate(direction: RotationDirection = "clockwise"): boolean {
    const piece = this.piece;
    const from = piece.rotation;
    const to =
      direction === "clockwise"
        ? from > 2
          ? 0
          : from + 1
        : from < 1
          ? 3
          : from - 1;

    const translation = Logic.wallkickRotation(
      this.board,
      piece.tetromino,
      piece.x,
      piece.y,
      from,
      to,
    );
    if (translation) {
      // success
      piece.rotation = to; // apply rotation and
      piece.x += translation.x; // move piece to
      piece.y -= translation.y; // checked position
      return true;
    } else {
      return false;
    }
  }

  private gameStep(movePiece: boolean = true) {
    // returns false if game over
    const collision = movePiece ? !this.move(0, 1) : true;
    if (collision) {
      const boardWithPiece = Logic.add(
        this.board,
        this.pieceShape,
        this.piece.x,
        this.piece.y,
      );

      if (!this.syncManager) {
        this.action({
          type: "setBoard",
          board: boardWithPiece,
        });

        const fullLines = Logic.getFullLines(boardWithPiece);

        if (fullLines.length > 0) {
          const removeLines = () => {
            const removeLinesAction: RemoveLinesAction = {
              type: "removeLines",
              lines: fullLines,
            };
            this.action(removeLinesAction);
          };

          if (this.singlePlayerFullLinesCallback) {
            this.singlePlayerFullLinesCallback(fullLines, removeLines);
          } else {
            removeLines();
          }
        }
      } else {
        this.action({
          type: "setBoard",
          board: boardWithPiece,
        });
      }

      this.gameOver = this.resetPiece();

      this.blockDataUpdateCallbacks.forEach((callback) => {
        callback(this.currentBlockData);
      });

      return this.gameOver;
    }
    return true;
  }

  private get pieceShape() {
    return this.piece.tetromino.rotation[this.piece.rotation];
  }

  private get pieceState(): PieceState {
    const tetrominoType: Data.TetrominoType = Object.keys(Data.Tetrominos).find(
      (key) => {
        return Data.Tetrominos[key] === this.piece.tetromino;
      },
    ) as Data.TetrominoType;

    return {
      x: this.piece.x,
      y: this.piece.y,
      rotation: this.piece.rotation,
      tetrominoType,
    };
  }

  public get blockData() {
    return this.board;
  }

  public get currentBlockData() {
    return Logic.add(this.board, this.pieceShape, this.piece.x, this.piece.y);
  }

  public registerActionCallback(callback: ActionCallback) {
    this.actionCallbacks.push(callback);
  }

  public registerBlockDataUpdateCallback(callback: BlockDataUpdateCallback) {
    this.blockDataUpdateCallbacks.push(callback);
  }

  // only necessary for dev implementation of board mirroring:
  public get currentPieceState(): PieceState {
    const Tetrominos = Data.Tetrominos;
    const tetrominoType = Object.keys(Tetrominos).find((key) => {
      return Tetrominos[key] === this.piece.tetromino;
    }) as Data.TetrominoType;

    return {
      x: this.piece.x,
      y: this.piece.y,
      rotation: this.piece.rotation,
      tetrominoType,
    };
  }
}
