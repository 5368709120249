import React, { ReactNode } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

const boardSize = 850

interface Props {
	direction : 'left' | 'right'
	clearedLines : number
}

export const PeerSpeedPopup = (props:Props) => {

	const direction = props.direction === 'left' ? 1 : -1
	const from = boardSize / 2 * direction
	const to = boardSize / 2 * -direction

	const variants = {
		initial : {
			x : from,
			opacity : 0
		},
		target : {
			x : [from, to, to],
			opacity : [0, 1, 0],
			transition : {
				ease : 'easeOut',
				duration : 2,
				times : [0, 0.5, 1]
			}	
		},
		exit : {
			opacity : 0
		}
	}

	return <Container>
					<AnimatePresence>
						{
							props.clearedLines > 0 &&
							<Container>
								<motion.div variants={variants}
														initial="initial"
														animate="target"
														exit="exit"
														key={props.clearedLines}>
									<Message>
										slow down
									</Message>
								</motion.div>
							</Container>
						}
					</AnimatePresence>
			</Container>
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: grid;
  place-items: center;
`

const Message = styled.div`
	width: ${boardSize}px;
	padding: 20px;
	text-align: center;

	color: rgba(225, 0, 15, 0.75);
`
