import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import * as Logic from "./core/logic.ts";
import { GameBoard, SinglePlayerFullLinesCallback } from "./core/GameBoard.ts";

import GameContainer from "./ui/game-container.tsx";
import {
  ControlsLayout,
  GameUserInterface,
  SingleplayerBoard,
} from "./ui/GameUserInterface.tsx";
import { GameBoardDisplay } from "./ui/GameBoardDisplay.tsx";
import { FullLinesOverlay } from "./ui/FullLinesOverlay.tsx";
import { InfoPopup } from "./ui/InfoPopup.tsx";

import { useFullLinesOverlayData } from "../hooks/useFullLinesOverlayData.ts";
import { useAutoGameStep } from "../hooks/useAutoGameStep.ts";
import { useKeyboardDriver } from "../hooks/useKeyboardDriver.ts";

interface Props {
  board: Logic.BlockData;
  nickname?: string;
  onGameOver?: (clearedLines: number) => void;
  onExitButton?: () => void;
  controlsLayout?: ControlsLayout;
}

export const SingleplayerGame = (props: Props) => {
  const [gameOver, setGameOver] = useState(false);
  const [pause, setPause] = useState(false);
  const [fullLines, setFullLines] = useState<Array<number>>([]);
  const clearedLines = useRef(0);

  const boardRows = Logic.blockHeight(props.board);
  const { rowHeight, boardRef, lines } = useFullLinesOverlayData(
    boardRows,
    fullLines,
  );

  const actionCallback = (action, over) => {
    if (!gameOver && over) {
      setGameOver(over);
      if (props.onGameOver) {
        props.onGameOver(clearedLines.current);
      }
    }
  };

  const singlePlayerFullLinesCallback: SinglePlayerFullLinesCallback = (
    fullLines,
    removeLines,
  ) => {
    setFullLines(fullLines);
    clearedLines.current += fullLines.length;
    setPause(true);
    setTimeout(async () => {
      removeLines();
      setFullLines([]);
      setPause(false);
    }, 1500);
  };

  const playerBoard = useRef<GameBoard>();
  if (playerBoard.current === undefined) {
    // lazy init
    const options = {
      actionCallback,
      singlePlayerFullLinesCallback,
    };
    playerBoard.current = new GameBoard(props.board, options);
  }

  useAutoGameStep({
    board: playerBoard,
    baseInterval: 1000,
    pause: gameOver || pause,
    dynamicSpeed: {
      clearedLines: clearedLines.current,
      intervalDecrementPerLine: 50,
      intervalIncrementPerPeerLine: 50,
      minimumInterval: 100,
      peerClearedLines: 0,
    },
  });

  useKeyboardDriver(playerBoard, {
    disabled: gameOver || pause,
  });

  return (
    <GameContainer>
      <GameUserInterface
        leftPlayerName={props.nickname}
        score={clearedLines.current}
        gameType="singleplayer"
        controlsBoard={playerBoard}
        layout={props.controlsLayout}
        disableControls={gameOver || pause}
        onExitButton={props.onExitButton}
      >
        <SingleplayerBoard ref={boardRef}>
          <GameBoardDisplay gameBoard={playerBoard} />
          <FullLinesOverlay lines={lines} lineHeight={rowHeight} />
        </SingleplayerBoard>
        {gameOver && (
          <InfoPopup>
            <div>Thank you for playing</div>
            <div>
              You created {clearedLines.current} bond
              {clearedLines.current === 1 ? "" : "s"}
            </div>
          </InfoPopup>
        )}
      </GameUserInterface>
    </GameContainer>
  );
};
