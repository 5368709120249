import { TurboMount } from "turbo-mount";
import { registerComponent } from "turbo-mount/react";

import App from "./components/App.jsx";
import SingleplayerApp from "./components/SingleplayerApp.tsx";

const turboMount = new TurboMount();

// to register a component use:
registerComponent(turboMount, "App", App);
registerComponent(turboMount, "SingleplayerApp", SingleplayerApp);

// to override the default controller use:
// registerComponent(turboMount, "Hello", Hello, HelloController); // where HelloController is a Stimulus controller extended from TurboMountController
