import React from "react";

import styled from "styled-components";

import { GameBoard } from "../core/GameBoard";
import { Button } from "./Button";
import { RotateClockwise, RotateCounterClockwise } from "./Icons";

interface Props {
  board: React.MutableRefObject<GameBoard>;
  onExit: () => void;
  disableExceptExit?: boolean;
}

export const MobileGameControls = (props: Props) => {
  return (
    <>
      <ControlsLeft>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "rotate",
                direction: "counterclockwise",
              });
            }
          }}
        >
          <RotateCounterClockwise />
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "rotate",
                direction: "clockwise",
              });
            }
          }}
        >
          <RotateClockwise />
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "down",
              });
            }
          }}
        >
          v
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "hardDrop",
              });
            }
          }}
        >
          V
        </Button>
      </ControlsLeft>
      <ExitButton>
        <Button width="273px" onClick={() => props.onExit()}>
          exit
        </Button>
      </ExitButton>
      <ControlsRight>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "left",
              });
            }
          }}
        >
          &lt;
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "right",
              });
            }
          }}
        >
          &gt;
        </Button>
      </ControlsRight>
    </>
  );
};

const ControlsLeft = styled.div`
  justify-self: start;
  display: flex;
`;
const ExitButton = styled.div`
  justify-self: center;
`;
const ControlsRight = styled.div`
  justify-self: end;
`;
