import React, { ReactNode } from "react";
import styled from "styled-components";

import { MobileGameControls } from "./MobileGameControls.tsx";
import { DesktopGameControls, PlayerSide } from "./DesktopGameControls.tsx";

import { GameBoard } from "../core/GameBoard.ts";

type GameType = "singleplayer" | "multiplayer";

interface Props {
  gameType: GameType;
  leftPlayerName?: string;
  rightPlayerName?: string;
  score: string | number;
  controlsBoard: React.MutableRefObject<GameBoard>;
  disableControls?: boolean;
  onExitButton?: () => void;
  layout?: ControlsLayout;
  playerSide?: PlayerSide;

  children: ReactNode;
}

export type ControlsLayout = "desktop" | "mobile";

export const GameUserInterface = (props: Props) => {
  const layout: ControlsLayout = props.layout ?? "desktop";
  const playerSide: PlayerSide = props.playerSide ?? "left";

  return (
    <Container>
      <TopBar>
        <PlayerName>{props.leftPlayerName}</PlayerName>
        <Score>{props.score}</Score>
        <PlayerName>{props.rightPlayerName}</PlayerName>
      </TopBar>

      <BoardContainer $gameType={props.gameType}>
        {props.children}
      </BoardContainer>

      <BottomBar $layout={layout}>
        {layout === "mobile" && (
          <MobileGameControls
            board={props.controlsBoard}
            onExit={() => {
              if (props.onExitButton) {
                props.onExitButton();
              }
            }}
            disableExceptExit={props.disableControls}
          />
        )}
        {layout === "desktop" && (
          <DesktopGameControls
            board={props.controlsBoard}
            onExit={() => {
              if (props.onExitButton) {
                props.onExitButton();
              }
            }}
            disableExceptExit={props.disableControls}
            playerSide={playerSide}
          />
        )}
      </BottomBar>
    </Container>
  );
};

const Container = styled.div`
  background: #ded7d6;
  width: 1920px;
  height: 1080px;

  font-size: 36px;
`;

const TopBar = styled.div`
  display: grid;
  grid-template-columns: 750px 100px 750px;
  justify-content: center;
  align-items: center;

  color: #e1000f;

  height: 100px;
  font-family: SegoeUIBold;
`;

const PlayerName = styled.div`
  text-align: center;
  font-size: 30px;
`;

const Score = styled.div`
  text-align: center;
  font-size: 80px;
`;

const BottomBar = styled.div<{ $layout: ControlsLayout }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$layout === "mobile" ? "1fr 200px 1fr" : "1fr 1fr"};

  justify-content: center;
  align-items: center;

  height: 130px;
  margin: 0px ${(props) => (props.$layout === "mobile" ? "30" : "120")}px;
`;

const BOARD_HEIGHT = "850px";

const BoardContainer = styled.div<{
  $gameType: "singleplayer" | "multiplayer";
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$gameType === "singleplayer" ? "auto" : "auto auto"};
  justify-items: center;
  gap: 5px;
  height: ${BOARD_HEIGHT};

  position: relative;
`;

export const MultiplayerBoard = styled.div<{
  $grayscale?: boolean;
  $leftSide?: boolean;
}>`
  filter: ${(props) => (props.$grayscale ? "grayscale()" : "unset")};
  width: calc(1920px / 2);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${BOARD_HEIGHT};
  justify-items: ${(props) => (props.$leftSide === true ? "end" : "start")};
`;

export const SingleplayerBoard = styled(MultiplayerBoard)`
  width: 1920px;
  justify-items: center;
`;
