import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  children: React.ReactNode;
}

export const InfoPopup = (props: Props) => {
  return (
    <Container>
      <Popup>{props.children}</Popup>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;

  display: grid;
  place-content: center;

  pointer-events: none;
`;

const appear = keyframes`
	0% {
		opacity : 0;
		transform: scale(0);
	}

	100% {
		transform: scale(1);
		opacity : 1;
	}
`;

const Popup = styled.div`
	width: 805px;
	padding: 24px 50px;
	box-sizing: border-box;

	display: grid;
	gap: 34px;
	place-content: center;
	justify-items: center;
	text-align: center;

	background: #DED7D6;
	color: #E1000F;
  	font-family: SegoeUIBold;
	font-size: 40px;

  text-transform: uppercase;

  animation: 0.9s ${appear} ease-out both;
`;
