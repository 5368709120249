import React, { ReactNode, useState } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  onClick?: () => void;
  width?: string;
}

export const Button = (props: Props) => {
  const [pressed, setPressed] = useState(false);

  return (
    <RedButton
      onClick={props.onClick}
      $width={props.width}
      $pressed={pressed}
      onPointerDown={() => setPressed(true)}
      onPointerUp={() => setPressed(false)}
      onPointerLeave={() => setPressed(false)}
      onPointerOut={() => setPressed(false)}
      onPointerCancel={() => setPressed(false)}
    >
      {props.children}
    </RedButton>
  );
};

const RedButton = styled.button<{ $width?: string; $pressed: boolean }>`
  font-family: SegoeUI;
  font-size: 30px;
  margin: 20px;

  color: #fff;
  background: #e1000f;
  border: 3px solid #fff;
  border-radius: 30px;

  height: 100px;
  min-width: 100px;
  width: ${(props) => (props.$width ? props.$width : undefined)};

  transition: 0.1s all ease-in-out;
  transform: scale(${(props) => (props.$pressed ? "0.9" : "1")});

  padding: 16px;

  cursor: pointer;
`;
