export interface BlockData {
	width : number
	data : Array<number>
}

export interface Tetromino {
	rotation : Array<BlockData>
	wallKickData : WallKickData
}

export interface WallKickData {
	tests : Array<WallKickTest>
}

export interface WallKickTest {
	fromRotation : number
	toRotation : number
	translations: Array<Position> // positive y moves up
}

export interface Position {
	x : number,
	y : number
}

const yFromIndex = (index:number, width:number) => Math.floor(index / width)

export const blockHeight = (blockData:BlockData) => {
	return yFromIndex(blockData.data.length, blockData.width)
}

export const positionFromIndex = (index:number, width:number):Position => {
	return {
		x : index % width,
		y : yFromIndex(index, width)
	}
}

const indexFromPosition = (x:number, y:number, width:number) => {
	return y * width + x	
}

export const add = (target:BlockData, brush:BlockData, x:number, y:number):BlockData => {

	const composed:BlockData = {
		width : target.width,
		data : [...target.data]
	}

	for(let i = 0; i < brush.data.length; i++){
		const value = brush.data[i]
		const source = positionFromIndex(i, brush.width)	
		const dx = x + source.x	
		const dy = y + source.y	

		if(value > 0 && dx >= 0 && dx < target.width && dy >= 0 && dy < blockHeight(target)){
			composed.data[indexFromPosition(dx, dy, composed.width)] = value
		}
	}

	return composed

}

export const intersects = (target:BlockData, brush:BlockData, x:number, y:number) => {

	for(let i = 0; i < brush.data.length; i++){
		const value = brush.data[i]
		if(value > 0){
			const source = positionFromIndex(i, brush.width)	
			const dx = x + source.x	
			const dy = y + source.y	
			const targetIndex = indexFromPosition(dx, dy, target.width)
			const verticalOutside = targetIndex < 0 || targetIndex >= target.data.length
			const horizontalOutside = dx < 0 || dx >= target.width
			const outsideTarget = verticalOutside || horizontalOutside

			if(outsideTarget){
				return true
			} else {
				const targetValue = target.data[targetIndex]
				if(targetValue > 0){
					return true
				}
			}
		}
	}

	return false
}

export const wallkickRotation = (board:BlockData, tetromino:Tetromino, x:number, y:number, from:number, to:number) => {

	// retrieve or generate wallkick test data for the rotation:
	let tests = tetromino.wallKickData.tests.find(test => test.fromRotation === from && test.toRotation === to)
	if(tests === undefined){
		// if no tests are found check if data is present for the reverse case
		const reverseTests = tetromino.wallKickData.tests.find(test => test.fromRotation === to && test.toRotation === from)
		if(reverseTests){ // and generate it from there
			tests = {
				fromRotation : reverseTests.toRotation,
				toRotation : reverseTests.fromRotation,
				translations : reverseTests.translations.map(t => {
					return {
						x : t.x * -1,
						y : t.y * -1
					}
				})
			}
		}
	}

	if(tests !== undefined){
		// check specified translations to see if rotation is possible:
		for(let i = 0; i < tests.translations.length; i++){
			const t = tests.translations[i]
			const rotatedPiece = tetromino.rotation[to]
			const blockedRotation = intersects(board, rotatedPiece, x + t.x, y- t.y)

			if(!blockedRotation){ // success rotating
				return t // return translation if rotation is possible
			}
		}
	}

}

const getLines = (board:BlockData):Array<Array<number>> => {
	let lines:Array<Array<number>> = []
	for(let i = 0; i < board.data.length; i += board.width){
		lines.push(board.data.slice(i, i + board.width))
	}
	return lines
}

export const getFullLines = (board:BlockData):Array<number> => {
	const lines = getLines(board)
	let fullLines:Array<number> = []
	lines.forEach((line, index) => {
		const filled = line.filter(block => block > 0).length === board.width
		if(filled){
			fullLines.push(index)
		}
	})
	return fullLines
}

export const removeLines = (board:BlockData, lines:Array<number>):BlockData => {
	let removedData = [... board.data]

	// mark lines for removal
	lines.forEach(lineIndex => {
		const lineStart = indexFromPosition(0, lineIndex, board.width)
		for(let i = lineStart; i < lineStart + board.width; i++){
			removedData[i] = -1
		}
	})
	// remove lines
	removedData = removedData.filter(block => block >= 0)
	// add empty lines on top
	const emptyLines = Array.from({ length : board.width * lines.length }, () => 0)	
	removedData.splice(0, 0, ...emptyLines)

	return {
		width : board.width,
		data : removedData
	}
}

export const getLinkedBoardFullLines = (board:BlockData, linkedBoard:BlockData) => {
	const fullLines = getFullLines(board)
	const linkedFullLines = getFullLines(linkedBoard)
	return fullLines.filter(line => linkedFullLines.includes(line))
}

export const filterLines = (board:BlockData, linkedBoard?:BlockData):BlockData => {
	let fullLines = linkedBoard ? getLinkedBoardFullLines(board, linkedBoard) : getFullLines(board)
	return removeLines(board, fullLines)
}

export const combineBlockData = (left:BlockData, right:BlockData):BlockData => {
	const leftLines = getLines(left)
	const rightLines = getLines(right)

	const data = leftLines.map((line, i) => line.concat(rightLines[i])).flat()

	return {
		width : left.width + right.width,
		data
	}
}
