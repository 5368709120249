import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import * as Logic from "./core/logic.ts";

import { useFullLinesOverlayData } from "../hooks/useFullLinesOverlayData.ts";

import { GameBoard } from "./core/GameBoard.ts";

import { useAutoGameStep } from "../hooks/useAutoGameStep.ts";
import { useKeyboardDriver } from "../hooks/useKeyboardDriver.ts";

import { GameBoardDisplay } from "./ui/GameBoardDisplay.tsx";
import { FullLinesOverlay } from "./ui/FullLinesOverlay.tsx";
import GameContainer from "./ui/game-container.tsx";

import { InfoPopup } from "./ui/InfoPopup.tsx";

import { PeerSpeedPopup } from "./ui/PeerSpeedPopup.tsx";

import {
  ControlsLayout,
  GameUserInterface,
  MultiplayerBoard,
} from "./ui/GameUserInterface.tsx";

interface Props {
  board: Logic.BlockData;
  mode: SyncManagerMode;
  score: number;
  peerClearedLines: number;
  nicknames?: {
    host: string;
    guest: string;
  };
  onGameOver?: (clearedLines: number) => void;
  gameOver?: boolean;
  otherPlayerDisconnected?: boolean;
  playerWaiting?: boolean;
  onExitButton?: () => void;
  controlsLayout?: ControlsLayout;
  transmitPieceCallback?: (action: SetPieceMessage) => void;
  transmitBoardCallback?: (action: SetBoardMessage) => void;
  transmitFullLinesCallback?: (fullLines: number) => void;
  receivedPieceAction?: SetPieceAction;
  receivedBoardAction?: SetBoardAction;
}

export const DualSingleplayerGame = (props: Props) => {
  const [gameOver, setGameOver] = useState(false);
  const [pause, setPause] = useState(false);
  const [fullLines, setFullLines] = useState<Array<number>>([]);
  const clearedLines = useRef(0);

  const boardRows = Logic.blockHeight(props.board);
  const { rowHeight, boardRef, lines } = useFullLinesOverlayData(
    boardRows,
    fullLines,
  );

  const otherPlayerName = props.nicknames
    ? props.mode === "HOST"
      ? props.nicknames.guest
      : props.nicknames.host
    : "Player 2";

  useEffect(() => {
    if (props.gameOver === true) {
      setGameOver(true);
    }
  }, [props.gameOver]);

  useEffect(() => {
    if (otherBoard.current && props.receivedPieceAction) {
      otherBoard.current.action({
        type: "setPiece",
        ...props.receivedPieceAction,
      });
    }
  }, [props.receivedPieceAction]);

  useEffect(() => {
    if (otherBoard.current && props.receivedBoardAction) {
      otherBoard.current.action({
        type: "setBoard",
        ...props.receivedBoardAction,
      });
    }
  }, [props.receivedBoardAction]);

  const actionCallback = (action, over) => {
    if (!gameOver && over) {
      setGameOver(over);
      if (over && props.onGameOver) {
        props.onGameOver(clearedLines.current);
      }
    }
  };

  const fullLinesCallback: SinglePlayerFullLinesCallback = (
    fullLines,
    removeLines,
  ) => {
    clearedLines.current += fullLines.length;
    setFullLines(fullLines);

    props.transmitFullLinesCallback(fullLines);

    setPause(true);
    setTimeout(async () => {
      removeLines();
      setFullLines([]);
      setPause(false);
    }, 1500);

    // transmit board after removing lines:
    const action: SetBoardAction = {
      type: "setBoard",
      action: playerBoard.current?.blockData,
    };
    props.transmitBoardCallback(action);
  };

  const hostBoard = useRef<GameBoard>();
  const guestBoard = useRef<GameBoard>();
  const playerBoard = useRef<GameBoard>();
  const otherBoard = useRef<GameBoard>();

  if (hostBoard.current === undefined) {
    // lazy init
    const options = {
      actionCallback,
      singlePlayerFullLinesCallback: fullLinesCallback,
    };
    const hostB = new GameBoard(
      props.board,
      props.mode === "HOST" ? options : undefined,
    );
    const guestB = new GameBoard(
      props.board,
      props.mode === "GUEST" ? options : undefined,
    );

    hostBoard.current = hostB;
    guestBoard.current = guestB;
    playerBoard.current = props.mode === "HOST" ? hostB : guestB;
    otherBoard.current = props.mode === "HOST" ? guestB : hostB;

    playerBoard.current.transmitPieceCallback = props.transmitPieceCallback;
    playerBoard.current.transmitBoardCallback = props.transmitBoardCallback;
  }

  useAutoGameStep({
    board: playerBoard,
    baseInterval: 1000,
    pause:
      gameOver || pause || props.otherPlayerDisconnected || props.playerWaiting,
    dynamicSpeed: {
      clearedLines: clearedLines.current,
      peerClearedLines: props.peerClearedLines,
      intervalDecrementPerLine: 100,
      intervalIncrementPerPeerLine: 50,
      minimumInterval: 100,
    },
  });

  useKeyboardDriver(playerBoard, {
    disabled: pause || gameOver || props.otherPlayerDisconnected,
    delay: props.devInputDelay,
  });

  const leftName =
    props.nicknames === undefined
      ? `Nickname HOST ${props.mode === "HOST" ? "board" : "mirror"}`
      : props.nicknames.host;
  const rightName =
    props.nicknames === undefined
      ? `Nickname GUEST ${props.mode === "GUEST" ? "board" : "mirror"}`
      : props.nicknames.guest;

  return (
    <GameContainer>
      <GameUserInterface
        leftPlayerName={leftName}
        rightPlayerName={rightName}
        score={props.score}
        gameType="multiplayer"
        controlsBoard={playerBoard}
        disableControls={gameOver || props.otherPlayerDisconnected}
        layout={props.controlsLayout}
        playerSide={props.mode === "HOST" ? "left" : "right"}
        onExitButton={props.onExitButton}
      >
        <MultiplayerBoard
          $grayscale={props.mode === "GUEST"}
          $leftSide
          ref={boardRef}
        >
          <GameBoardDisplay gameBoard={hostBoard} />
        </MultiplayerBoard>
        <MultiplayerBoard $grayscale={props.mode === "HOST"}>
          <GameBoardDisplay gameBoard={guestBoard} />
        </MultiplayerBoard>
        <FullLinesOverlay lines={lines} lineHeight={rowHeight} />
        <PeerSpeedPopup
          direction={props.mode === "HOST" ? "left" : "right"}
          clearedLines={props.peerClearedLines}
        />
        {gameOver && (
          <InfoPopup>
            <div>Thank you for playing</div>
            <div>
              You created {clearedLines.current} bond
              {clearedLines.current === 1 ? "" : "s"} together
            </div>
          </InfoPopup>
        )}
        {props.otherPlayerDisconnected === true && (
          <InfoPopup>
            <div>{otherPlayerName} got disconnected</div>
            <div>Please exit to restart a game</div>
          </InfoPopup>
        )}
        {props.playerWaiting === true && (
          <InfoPopup>
            <div>Please wait a moment</div>
            <div>for your colleague to join you</div>
          </InfoPopup>
        )}
      </GameUserInterface>
    </GameContainer>
  );
};
