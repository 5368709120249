import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { GameBoard } from "../core/GameBoard.ts";
import * as Logic from "../core/logic.ts";
import { BlockDataDisplay } from "./BlockDataDisplay.tsx";

interface Props {
  gameBoard: MutableRefObject<GameBoard>;
}

export const GameBoardDisplay = (props: Props) => {
  const [gameBoardData, setGameBoardData] = useState(
    props.gameBoard.current!.currentBlockData,
  );

  const onBlockDataUpdate = (blockData: Logic.BlockData) => {
    setGameBoardData(blockData);
  };

  useEffect(() => {
    props.gameBoard.current.registerBlockDataUpdateCallback(onBlockDataUpdate);
  }, [props.gameBoard.current]);

  return <BlockDataDisplay blockData={gameBoardData} />;
};
