import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Autoplay, FreeMode } from "swiper/modules";

// Connects to data-controller="swiper"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      modules: [Autoplay, FreeMode],
      speed: 5000,
      autoplay: {
        delay: 1,
      },
      slidesPerView: "auto",
      spaceBetween: 10,
      centeredSlides: true,
      loop: true,
    });
  }
}
