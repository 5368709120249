import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

// Connects to data-controller="cable-demo"
export default class extends Controller {
  static targets = ["team"];

  connect() {
    if (isPreview()) return;

    this.channel = consumer.subscriptions.create("TeamChannel", {});

    this.channel.connected = () => {
      console.log("connected");
    };

    this.channel.disconnected = () => {
      console.log("disconnected");
    };

    this.channel.received = this.handleMessage.bind(this);
  }

  handleMessage(data) {
    const message = JSON.parse(data);

    console.log(message);

    this.teamTargets.find(
      (target) => target.id === `team_${message.team_id}`,
    ).innerHTML = `
      Team ${message.team_id}: ${message.x}, ${message.y}
    `;
  }
}

const isPreview = () => {
  return document.documentElement.hasAttribute("data-turbo-preview");
};
