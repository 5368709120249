import { useEffect, useState } from "react"
import useResizeObserver from "use-resize-observer"

import * as Logic from "../components/core/logic.ts"

export const useRowHeight = (rowCount:number) => {
	
	const [rowHeight, setRowHeight] = useState<number>(0);
	const resizeObserverHook = useResizeObserver<HTMLDivElement>()

	useEffect(() => {
		if (resizeObserverHook.height) {
			setRowHeight(resizeObserverHook.height / rowCount);
		}
	}, [resizeObserverHook.height]);

	return { rowHeight, boardRef : resizeObserverHook.ref  }
}