import React from "react";

export const RotateClockwise = () => {
  return (
    <svg
      width="60"
      height="66"
      viewBox="0 0 60 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9684 10.7619C18.3637 11.7582 10.5618 20.0711 9.50434 30.4609C8.13278 43.9383 17.8368 55.3437 30 55.3437C40.709 55.3437 49.5122 46.5017 50.5275 35.1887C50.6334 34.0105 49.754 33 48.6534 33C47.67 33 46.8862 33.8094 46.7887 34.8596C45.929 44.0825 38.7403 51.2812 30 51.2812C20.0503 51.2812 12.1125 41.9537 13.23 30.9301C14.0962 22.3837 20.5725 15.5455 28.4793 14.7919C33.9018 14.2749 38.834 16.553 42.2297 20.4276L44.8359 17.4863C40.6528 12.7931 34.5965 10.0743 27.9684 10.7619Z"
        fill="white"
      />
      <path
        d="M46.049 12.4377L37.7943 22.4121C37.0762 23.2795 37.5712 24.6638 38.6418 24.7806L49.0734 25.917C49.9593 26.0135 50.6756 25.1482 50.4909 24.2037L48.314 13.0927C48.0909 11.9522 46.7672 11.5703 46.049 12.4377Z"
        fill="white"
      />
    </svg>
  );
};

export const RotateCounterClockwise = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.167 10.104C44.412 11.085 52.734 19.27 53.862 29.5C55.325 42.77 44.974 54 32 54C20.577 54 11.187 45.294 10.104 34.155C9.99097 32.995 10.929 32 12.103 32C13.152 32 13.988 32.797 14.092 33.831C15.009 42.912 22.677 50 32 50C42.613 50 51.08 40.816 49.888 29.962C48.964 21.547 42.056 14.814 33.622 14.072C27.838 13.563 22.577 15.806 18.955 19.621L16.175 16.725C20.637 12.104 27.097 9.42699 34.167 10.104Z"
        fill="white"
      />
      <path
        d="M14.881 11.754L23.686 21.575C24.452 22.429 23.924 23.792 22.782 23.907L11.655 25.026C10.71 25.121 9.946 24.269 10.143 23.339L12.465 12.399C12.703 11.276 14.115 10.9 14.881 11.754Z"
        fill="white"
      />
    </svg>
  );
};
