import prand from 'pure-rand'

import * as Data from './data.ts'

export class RandomGenerator {

	private rng = prand.xoroshiro128plus(42) // seed value
	private bag:Array<Data.TetrominoType> = []

	private fillBag() {
		const pieces:Array<Data.TetrominoType> = [
			'o' , 'i' , 'j' , 'l' , 't' , 's' , 'z', 'dot'
		]
		while(pieces.length > 0){
			const randomIndex = prand.unsafeUniformIntDistribution(0, pieces.length - 1, this.rng)
			this.bag.push(pieces.splice(randomIndex, 1)[0])
		}
	}

	public nextTetromino() {
		if(this.bag.length === 0){
			this.fillBag()
		}
		return this.bag.pop() as Data.TetrominoType
	}

}