import React from "react";

import styled from "styled-components";

import { GameBoard } from "../core/GameBoard";
import { Button } from "./Button";
import { RotateClockwise, RotateCounterClockwise } from "./Icons";

interface Props {
  board: React.MutableRefObject<GameBoard>;
  onExit: () => void;
  disableExceptExit?: boolean;
  playerSide? : PlayerSide
}

export type PlayerSide = 'left' | 'right'

export const DesktopGameControls = (props: Props) => {

  const playerSide = props.playerSide ?? 'left'

  return (
    <>
      <Controls $playerSide={playerSide}>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "rotate",
                direction: "counterclockwise",
              });
            }
          }}
        >
          <RotateCounterClockwise />
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "left",
              });
            }
          }}
        >
          &lt;
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "down",
              });
            }
          }}
        >
          v
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "hardDrop",
              });
            }
          }}
        >
          V
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "move",
                direction: "right",
              });
            }
          }}
        >
          &gt;
        </Button>
        <Button
          onClick={() => {
            if (!props.disableExceptExit) {
              props.board.current?.action({
                type: "rotate",
                direction: "clockwise",
              });
            }
          }}
        >
          <RotateClockwise />
        </Button>
      </Controls>
      <ExitButton $playerSide={playerSide}>
        <Button width="273px" onClick={() => props.onExit()}>
          exit
        </Button>
      </ExitButton>
    </>
  );
};

const Controls = styled.div<{$playerSide:PlayerSide}>`
  justify-self: start;
  display: flex;
  order : ${props => props.$playerSide === 'left' ? '1' : '2'};
`;
const ExitButton = styled.div<{$playerSide:PlayerSide}>`
  justify-self: center;
  order : ${props => props.$playerSide === 'left' ? '2' : '1'};
`;