import React, { useEffect, MutableRefObject } from 'react'

import { GameBoard } from '../components/core/GameBoard.ts'

interface Options {
	onKey? : (e:KeyboardEvent) => void
	delay? : number
	disabled? : boolean
}

export const useKeyboardDriver = (gameBoard:MutableRefObject<GameBoard|undefined>, options?:Options) => {

	useEffect(() => {
		const eventHandler = (e:KeyboardEvent) => {
			const delay = options?.delay ?? 0

			const board = gameBoard.current!
			if(e.key === 's'){
				setTimeout(() => {
					board.action({
						type : 'move',
						direction : 'down'
					})
				}, delay)
			}
			if(e.key === 'w'){
				setTimeout(() => {
					board.action({
						type : 'hardDrop'
					})
				}, delay)
			}
			if(e.key === 'a'){
				setTimeout(() => {
					board.action({
						type : 'move',
						direction : 'left'
					})
				}, delay)
			}
			if(e.key === 'd'){
				setTimeout(() => {
					board.action({
						type : 'move',
						direction : 'right'
					})
				}, delay)
			}

			if(e.key === 'e'){
				setTimeout(() => {
					board.action({
						type : 'rotate',
						direction : 'clockwise'
					})
				}, delay)
			}
			if(e.key === 'q'){
				setTimeout(() => {
					board.action({
						type : 'rotate',
						direction : 'counterclockwise'
					})
				}, delay)
			}

			if(options?.onKey){
				options.onKey(e)
			}
		}

		const disabled = options?.disabled === true
		if(!disabled){
			window.addEventListener('keydown', eventHandler)
		}

		return () => {
			window.removeEventListener('keydown', eventHandler)
		}
	}, [options])

}