import React, { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import { patch } from "@rails/request.js";
import consumer from "../channels/consumer";

import { ActionCableSubscription } from "@anycable/core/create-cable";

import { MultiplayerGame } from "./MultiplayerGame";
import { SingleplayerGame } from "./SingleplayerGame";
import { DualSingleplayerGame } from "./DualSingleplayerGame";

import {
  BoardSyncManager,
  SyncManagerMode,
  SetBoardSendFunction,
  SetPieceSendFunction,
} from "./core/BoardSyncManager.ts";
import { eighteenBoard } from "./dev/dev-data";

export default function App({ attempt, user, host, guest, nicknames, mobile }) {
  const [channel, setChannel] = useState(null);

  const [syncManagerMode, setSyncManagerMode] = useState<
    SyncManagerMode | undefined
  >(user === host ? "HOST" : "GUEST");

  const [multiplayerGameOver, setMultiplayerGameOver] = useState(false);

  const [receivedPieceAction, setReceivedPieceAction] = useState(null);
  const [receivedBoardAction, setReceivedBoardAction] = useState(null);

  const [score, setScore] = useState(0);
  const [otherPlayerDisconnected, setOtherPlayerDisconnected] = useState(false);
  const [playerWaiting, setPlayerWaiting] = useState(true);
  const [connectedPlayers, setConnectedPlayers] = useState(0);
  const peerClearedLines = useRef(0);

  const subscriptionRef = useRef<ActionCableSubscription>();
  let syncGameOver: (clearedLines: number) => void;

  useEffect(() => {
    const handleReceivedPiece = (message) => {
      setReceivedPieceAction(message.action);
    };
    const handleReceivedBoard = (message) => {
      setReceivedBoardAction(message.action);
    };
    const handleReceivedFullLines = (message) => {
      // message.clearedLines contains the line count maintained by the server
      setScore(message.clearedLines);
    };

    const subscription = consumer.subscriptions.create(
      { channel: "AttemptChannel", gid: attempt },
      {
        connected: () => {
          console.log("connected");
        },

        disconnected: () => {
          console.log("disconnected");
          // TODO any cleanup, for example, how to deal with abandoned games?
        },

        received: async (message) => {
          if (message.type === "playersChanged") {
            const oldPlayersCount = connectedPlayers;
            const newPlayersCount = message.players.length;

            if (newPlayersCount < 2) {
              if (oldPlayersCount >= 2) {
                setOtherPlayerDisconnected(true);
                setPlayerWaiting(false);
              } else {
                setPlayerWaiting(true);
                setOtherPlayerDisconnected(false);
              }
            } else {
              setPlayerWaiting(false);
              setOtherPlayerDisconnected(false);
            }
          }

          if (message.type === "gameOver") {
            setMultiplayerGameOver(true);

            const attemptForm = document.querySelector("#attempt-form");
            attemptForm.querySelector("#attempt_bonds_count").value =
              message.bondsCount;

            setTimeout(() => {
              attemptForm.requestSubmit();
            }, 3000);
          }

          if (message.type === "fullLines") {
            handleReceivedFullLines(message);
          }

          if (message.sender === user) return;

          const { actionData, ...rest } = message;
          message = { ...rest, action: actionData };

          if (message.type === "setPiece") {
            handleReceivedPiece(message);
          } else if (message.type === "setBoard") {
            handleReceivedBoard(message);
          }

          if (message.type === "fullLines") {
            peerClearedLines.current += message.fullLines.length;
          }
        },
      },
    );
    setChannel(subscription);

    syncGameOver = (clearedLines) => {
      subscription.send({
        type: "gameOver",
        bondsCount: clearedLines,
      });

      setMultiplayerGameOver(true);
    };

    subscriptionRef.current = subscription;

    return () => {
      if (subscriptionRef) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, []);

  if (isPreview()) return;

  return (
    <>
      {syncManagerMode && (
        <DualSingleplayerGame
          board={eighteenBoard}
          mode={syncManagerMode}
          score={score}
          controlsLayout={mobile ? "mobile" : "desktop"}
          peerClearedLines={peerClearedLines.current}
          transmitPieceCallback={(message) => {
            subscriptionRef.current?.send({
              actionData: message.action,
              ...message,
              sender: user,
            });
          }}
          transmitBoardCallback={(message) => {
            subscriptionRef.current?.send({
              actionData: { board: message.action },
              ...message,
              sender: user,
            });
          }}
          transmitFullLinesCallback={(fullLines) => {
            subscriptionRef.current?.send({
              type: "fullLines",
              fullLines,
              sender: user,
            });
          }}
          receivedPieceAction={receivedPieceAction}
          receivedBoardAction={receivedBoardAction}
          nicknames={nicknames}
          gameOver={multiplayerGameOver}
          otherPlayerDisconnected={otherPlayerDisconnected}
          playerWaiting={playerWaiting}
          onGameOver={(clearedLines) => {
            if (syncGameOver) {
              syncGameOver(clearedLines);
            }
          }}
          onExitButton={() => {
            Turbo.visit("/attempts");
          }}
        />
      )}
    </>
  );
}

const isPreview = () => {
  return document.documentElement.hasAttribute("data-turbo-preview");
};
