import * as Logic from './logic.ts'

const tetrominoFromShapes = (width:number, shapes:Array<Array<number>>, wallKickData:Logic.WallKickData):Logic.Tetromino => {

	return {
		rotation : shapes.map(s => {
			return { width, data : s }
		}),
		wallKickData
	}
}

// see https://tetris.fandom.com/wiki/SRS
const jltszWallKickData:Logic.WallKickData = {
	tests : [
		{
			fromRotation : 0,
			toRotation : 1,
			translations : [
				{ x : 0, y : 0},
				{ x : -1, y : 0},
				{ x : -1, y : 1},
				{ x : 0, y : -2},
				{ x : -1, y : -2}
			]
		},
		{
			fromRotation : 1,
			toRotation : 2,
			translations : [
				{ x : 0, y : 0},
				{ x : 1, y : 0},
				{ x : 1, y : -1},
				{ x : 0, y : 2},
				{ x : 1, y : 2}
			]
		},
		{
			fromRotation : 2,
			toRotation : 3,
			translations : [
				{ x : 0, y : 0},
				{ x : 1, y : 0},
				{ x : 1, y : 1},
				{ x : 0, y : -2},
				{ x : 1, y : -2}
			]
		},
		{
			fromRotation : 3,
			toRotation : 0,
			translations : [
				{ x : 0, y : 0},
				{ x : -1, y : 0},
				{ x : -1, y : -1},
				{ x : 0, y : 2},
				{ x : -1, y : 2}
			]
		}
	]	
}
const iWallKickData:Logic.WallKickData = {
	tests : [
		{
			fromRotation : 0,
			toRotation : 1,
			translations : [
				{ x : 0, y : 0},
				{ x : -2, y : 0},
				{ x : 1, y : 0},
				{ x : -2, y : -1},
				{ x : 1, y : 2}
			]
		},
		{
			fromRotation : 1,
			toRotation : 2,
			translations : [
				{ x : 0, y : 0},
				{ x : -1, y : 0},
				{ x : 2, y : 0},
				{ x : -1, y : 2},
				{ x : 2, y : -1}
			]
		},
		{
			fromRotation : 2,
			toRotation : 3,
			translations : [
				{ x : 0, y : 0},
				{ x : 2, y : 0},
				{ x : -1, y : 0},
				{ x : 2, y : 1},
				{ x : -1, y : -2}
			]
		},
		{
			fromRotation : 3,
			toRotation : 0,
			translations : [
				{ x : 0, y : 0},
				{ x : 1, y : 0},
				{ x : -2, y : 0},
				{ x : 1, y : -2},
				{ x : -2, y : 1}
			]
		}
	]	
}

const oWallKickData:Logic.WallKickData = {
	tests : [
		{
			fromRotation : 0,
			toRotation : 1,
			translations : [
				{ x : 0, y : 0}
			]
		},
		{
			fromRotation : 1,
			toRotation : 2,
			translations : [
				{ x : 0, y : 0}
			]
		},
		{
			fromRotation : 2,
			toRotation : 3,
			translations : [
				{ x : 0, y : 0}
			]
		},
		{
			fromRotation : 3,
			toRotation : 0,
			translations : [
				{ x : 0, y : 0}
			]
		},
	]
}

export const jPiece = tetrominoFromShapes(3, [
	[
		2,0,0,
		2,2,2,
		0,0,0
	],
	[
		0,2,2,
		0,2,0,
		0,2,0
	],
	[
		0,0,0,
		2,2,2,
		0,0,2
	],
	[
		0,2,0,
		0,2,0,
		2,2,0
	]
], jltszWallKickData)

export const lPiece = tetrominoFromShapes(3, [
	[
		0,0,3,
		3,3,3,
		0,0,0
	],
	[
		0,3,0,
		0,3,0,
		0,3,3
	],
	[
		0,0,0,
		3,3,3,
		3,0,0
	],
	[
		3,3,0,
		0,3,0,
		0,3,0
	]
], jltszWallKickData)

export const tPiece = tetrominoFromShapes(3, [
	[
		0,4,0,
		4,4,4,
		0,0,0
	],
	[
		0,4,0,
		0,4,4,
		0,4,0
	],
	[
		0,0,0,
		4,4,4,
		0,4,0
	],
	[
		0,4,0,
		4,4,0,
		0,4,0
	]
], jltszWallKickData)

export const sPiece = tetrominoFromShapes(3, [
	[
		0,5,5,
		5,5,0,
		0,0,0
	],
	[
		0,5,0,
		0,5,5,
		0,0,5
	],
	[
		0,0,0,
		0,5,5,
		5,5,0
	],
	[
		5,0,0,
		5,5,0,
		0,5,0
	]
], jltszWallKickData)

export const zPiece = tetrominoFromShapes(3, [
	[
		6,6,0,
		0,6,6,
		0,0,0
	],
	[
		0,0,6,
		0,6,6,
		0,6,0
	],
	[
		0,0,0,
		6,6,0,
		0,6,6
	],
	[
		0,6,0,
		6,6,0,
		6,0,0
	]
], jltszWallKickData)

export const iPiece = tetrominoFromShapes(4, [
	[
		0,0,0,0,
		7,7,7,7,
		0,0,0,0,
		0,0,0,0
	],
	[
		0,0,7,0,
		0,0,7,0,
		0,0,7,0,
		0,0,7,0
	],
	[
		0,0,0,0,
		0,0,0,0,
		7,7,7,7,
		0,0,0,0
	],
	[
		0,7,0,0,
		0,7,0,0,
		0,7,0,0,
		0,7,0,0
	]
], iWallKickData)

export const oPiece = tetrominoFromShapes(2, [
	[
		8,8,
		8,8,
	],
	[
		8,8,
		8,8,
	],
	[
		8,8,
		8,8,
	],
	[
		8,8,
		8,8,
	]
], oWallKickData)

export const dotPiece = tetrominoFromShapes(1, [
	[
		9,
	],
	[
		9,
	],
	[
		9,
	],
	[
		9,
	]
], oWallKickData)

export type TetrominoType = 'o' | 'i' | 'j' | 'l' | 't' | 's' | 'z' | 'dot'

type TetrominoMapping = {
  [key in TetrominoType] : Logic.Tetromino
}

export const Tetrominos:TetrominoMapping = {
    o: oPiece,
    i: iPiece,
    j: jPiece,
    l: lPiece,
    t: tPiece,
    s: sPiece,
    z: zPiece,
    dot: dotPiece
}
