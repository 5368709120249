import React from "react";

import { GameBoard } from "../components/core/GameBoard";
import useInterval from "./useInterval";

interface Options {
  board: React.MutableRefObject<GameBoard | undefined>;
  baseInterval: number;
  pause?: boolean;
  dynamicSpeed?: {
    clearedLines: number;
    peerClearedLines: number;
    intervalDecrementPerLine: number;
    intervalIncrementPerPeerLine: number;
    minimumInterval: number;
  };
}

export const useAutoGameStep = (options: Options) => {
  let speed = options.baseInterval;
  if (options.dynamicSpeed) {
    const dynamic = options.dynamicSpeed;
    const dynamicSpeed =
      options.baseInterval -
      dynamic.clearedLines * dynamic.intervalDecrementPerLine +
      dynamic.peerClearedLines * dynamic.intervalIncrementPerPeerLine;
    speed = Math.max(dynamic.minimumInterval, dynamicSpeed);
    // console.log(`peerClearedLines: ${dynamic.peerClearedLines} speed: ${speed}`)
  }

  useInterval(
    () => {
      if (options.board.current) {
        options.board.current!.action({ type: "gameStep" });
      }
    },
    options.pause === true ? null : speed,
  );
};
