import { ActionHistory } from './GameBoard'
import * as Logic from './logic.ts'

export const standardBoard:Logic.BlockData = {
	width : 10,
	data : [
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,
	]
}

export const twelveBoard:Logic.BlockData = {
    width : 12,
    data : [
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,
    ]
}

export const eighteenBoard:Logic.BlockData = {
    width : 18,
    data : [
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
    ]
}

export const thirtysixByEighteenBoard:Logic.BlockData = {
    width : 36,
    data : [
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    ]
}

export const devGameA:ActionHistory = 
{
    "actions": [
        {
            "timestamp": 1042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 1131,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 1417,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 1755,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 1993,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 2040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 2135,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 2410,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 2768,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 3123,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 3404,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 3671,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3843,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3968,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 4095,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4226,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4362,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4507,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4647,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4780,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4930,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 5039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 5160,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 5504,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 5736,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 6218,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6404,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6575,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6750,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6923,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 7040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 7108,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 7297,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 7466,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 7636,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 7947,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 8039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 8391,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 8553,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 8711,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 8858,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 8989,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 9039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 9204,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 9495,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 9915,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 10040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 10251,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 10410,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 10688,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 11039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 11061,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11241,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11413,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11533,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11783,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11912,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 12056,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12220,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12372,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12734,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12901,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 13104,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13352,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13526,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13668,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13850,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13997,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 14040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 14154,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 14322,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 14464,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 15040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 15359,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 15524,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 16684,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 17040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 17501,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17638,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17811,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17974,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 18109,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18265,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18411,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18545,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 19428,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19582,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19763,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 20039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 20203,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 20835,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 21040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 21136,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 21895,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 22040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 22558,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 22714,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 22863,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 23024,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 23039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 23305,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 24039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 24141,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 24507,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 25040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 25220,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 25550,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 26039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 26093,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 26467,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 27009,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 27039,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 27668,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 27913,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 28038,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 28497,
            "action": {
                "type": "hardDrop"
            }
        }
    ]
}


export const devGameB:ActionHistory = 
{
    "actions": [
        {
            "timestamp": 1041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 1053,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 1458,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 1751,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 1881,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 2041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 2192,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 2461,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 2711,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 2900,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3024,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 3374,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 3572,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3694,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3809,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 3997,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 4172,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4335,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4455,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4567,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4669,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 4821,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 5008,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 5041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 5301,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 6424,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 6616,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 6788,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 7005,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 7041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 7128,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 7426,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 7724,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 8011,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 8041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 8526,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 9042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 9086,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 9499,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9645,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9817,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 10042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 10155,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 10411,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 10530,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 10616,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 10773,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 10966,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 11108,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11294,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11635,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 11856,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12005,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 12181,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 12391,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 12562,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 12728,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 12892,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 13110,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 13261,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 14042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 14306,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 14663,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14834,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14950,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 15042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 15044,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 15206,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 15499,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 15694,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 16042,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 16044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 16464,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16622,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16776,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16993,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 17041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 17202,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 17454,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17620,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17755,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 17915,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 18088,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 18634,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 18958,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 19042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 19244,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 19448,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 20043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 20179,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 20408,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 20605,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 20772,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 20939,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 21042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 21094,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 21549,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 21917,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 22043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 22093,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 22280,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 22618,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 22816,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 23042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 23264,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 23453,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 23573,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 23808,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 24004,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 24042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 24175,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 24320,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 24614,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 25005,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 25042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 25157,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 25468,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 25598,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 26042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 26044,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 26202,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 26403,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 26815,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 26976,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 27042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 27123,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 27288,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 27582,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 28008,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 28042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 28159,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 28331,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 28783,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 29101,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 29550,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 29713,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 29803,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 30042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 30071,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 30239,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 30372,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 30541,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 30690,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 31444,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 31738,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 32043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 32069,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 32597,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 32911,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 33042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 33194,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 33446,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 33619,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 33931,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 34043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 34282,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 34682,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 34884,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 35042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 35754,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 35966,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 36042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 36353,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 36808,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 36984,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 37137,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37275,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37395,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37668,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 38042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 38059,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 38233,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 38441,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 38844,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 39042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 39409,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 40042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 40946,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 41043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 41343,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 41492,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 41760,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 41940,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 42042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 42276,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 42610,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 42816,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 42951,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 43042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 43407,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 43813,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 44042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 44088,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 44333,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 44565,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 44723,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 44901,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 45043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 45169,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 45635,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 46043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 46280,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 46553,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 46690,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 46832,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 46958,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 47043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 47375,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 47676,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 47994,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 48042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 48320,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 48499,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 48677,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 48885,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 49042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 49686,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 49899,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 50042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 50225,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 50388,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 50540,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 50699,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 50942,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 51042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 51516,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 51809,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 52042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 52091,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 52522,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 52677,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 53043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 53077,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 53294,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 53525,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 53687,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 54008,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 54043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 54458,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 54600,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 54837,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 54992,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 55043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 55129,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 55400,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 55573,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 55745,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 55969,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 56042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 56145,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 56640,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 56876,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57016,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 57148,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57305,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57490,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57666,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 57893,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 58042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 58382,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 58554,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 58700,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 58894,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 59043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 59178,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 59544,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 59930,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 60042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 61042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 61562,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 61920,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 62042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 62714,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 63042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 64043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 64193,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 64281,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 64897,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 65001,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 65209,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65665,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65906,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 66042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 66100,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 66228,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 66669,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 67042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 67530,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 67709,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 68043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 68650,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 69043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 69310,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 69713,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 69875,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 70043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 70228,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 70443,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 70629,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 70848,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 70992,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 71042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 71243,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 71460,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 71886,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 72042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 72049,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 72226,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 72370,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 72831,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 73042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 73230,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 73776,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 74043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 74108,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 74310,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 75043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 75051,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75214,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75400,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 76043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 76097,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 76325,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 76569,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 76954,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 77043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 77325,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 77614,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 77810,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 78043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 78179,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 78390,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 78544,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 78991,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 79042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 79780,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 79947,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 80042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 80176,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 80360,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 80589,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 80742,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 81041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 81181,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 81673,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 81831,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 81975,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 82041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 82257,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 82446,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 82611,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 82935,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 83042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 83149,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 83413,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 83809,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 83959,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 84042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 84252,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 84491,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 84799,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 84983,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 85042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 85507,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 85877,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86022,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 86174,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86288,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86474,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 86686,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86939,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 87042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 87342,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 87498,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 87643,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 88002,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 88042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 88420,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 88567,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 88719,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 88923,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 89042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 89135,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 89277,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 89405,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 89655,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 90042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 90050,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 90209,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 90347,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 90533,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 90798,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 91026,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 91042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 91318,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 91812,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 91978,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 92042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 92167,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 92321,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 92645,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 93043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 93140,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 93378,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 93661,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 93996,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 94042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 94181,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 94404,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 94559,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 94988,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 95043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 95328,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 95480,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 95645,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 95949,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 96042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 96516,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 96709,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 97042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 97177,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 97484,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 97935,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 98042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 98678,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 99042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 99047,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 99263,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 99699,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 100038,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 100041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 100253,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 100587,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 100716,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 100914,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 101042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 101160,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 101529,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 101683,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 102042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 102213,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 102577,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 103042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 103158,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 103472,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 103629,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 104255,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 104503,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104970,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 105042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 105150,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 105306,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 105536,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 105763,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 106033,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 106041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 106417,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 106655,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 106849,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 107004,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 107041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 107311,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 107530,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 107799,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 107950,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 108042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 108257,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 108665,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 108816,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 109048,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109479,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 109818,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109976,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 110042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 110124,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 110363,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 110608,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 110756,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 111042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 111102,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 111524,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 111843,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 112009,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 112041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 112146,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 112376,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 112686,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 113042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 113071,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113420,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 113562,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 113670,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 113801,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 113975,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 114041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 114165,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 114521,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 114657,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 114902,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 115042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 115547,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 115960,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 116042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 116295,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 116568,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 116794,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 117042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 117155,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 117636,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 118042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 118346,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 118747,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 118898,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 119042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 119047,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 119283,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 119650,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 120042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 120196,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 120683,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 121041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 121199,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 121333,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 122041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 122270,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 122582,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 122712,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 122827,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 123042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 123074,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 123659,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 124010,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 124042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 124694,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 125042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 125238,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 125395,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 125562,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 125791,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 125931,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 126041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 126099,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 126249,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 126412,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 126566,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 126845,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 127042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 127324,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 127733,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 128041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 128189,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 128581,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 128714,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 128962,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 129042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 129167,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 129319,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 129728,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 130041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 130056,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 130303,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 131041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 131051,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 131346,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 131536,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 131860,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 132041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 132119,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 132512,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 132728,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 133042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 133149,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 133563,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 133823,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 134042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 134114,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 134519,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 135041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 135242,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 135391,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 135688,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 135901,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 136042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 136153,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 136315,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 136450,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 137042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 137055,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 137503,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 137642,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 138041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 138048,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 138377,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 138780,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 138921,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 139035,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 139041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 139258,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 139427,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 139647,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 140041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 140092,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 140455,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 140633,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 140776,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 141042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 141197,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 141382,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 141522,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 141610,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 141856,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 142041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 142279,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 142850,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 143042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 143087,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 143383,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 143929,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 144041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 144082,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 144203,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 144680,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 144984,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 145041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 145719,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 146041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 146206,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 146356,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 146691,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 146973,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 147041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 147134,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 147272,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 147691,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 148042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 148214,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 148382,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 148552,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 148700,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 148856,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 149013,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 149041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 149178,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 149351,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 149559,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 150041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 150154,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 150955,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 151041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 151110,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 151342,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 151574,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 151725,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 151903,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 152041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 152157,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 152738,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 153042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 153164,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 153578,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 153931,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 154040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 154106,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 154341,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 154496,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 154631,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 154744,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 155042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 155426,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 155605,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 155814,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 155976,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 156041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 156263,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 156582,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 156734,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 157041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 157280,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 157489,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 157631,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 157879,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 158024,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 158041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 158304,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 158614,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 158800,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 159015,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 159042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 159289,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 159670,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 159981,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 160041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 160181,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 160337,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 160504,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 160605,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 160825,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 161041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 161097,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 161681,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 162042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 162157,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 162288,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 162487,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 162642,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 162823,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 163020,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 163041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 163216,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 163351,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 163684,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 164016,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 164041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 164249,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 164407,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 164637,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 164803,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 164987,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 165042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 165166,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 165325,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 165498,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 165642,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 165929,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 166041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 166212,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 166380,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 166569,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 166924,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 167041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 167260,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 167585,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 167727,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 168041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 168197,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 168375,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 168708,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 169042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 169055,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 169369,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 169546,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 169604,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 169870,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 170041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 170064,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 170585,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 171040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 171156,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 171315,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 171603,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 171798,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 171952,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 172041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 172242,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 172556,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 172949,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 173041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 173177,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 173357,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 173703,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 173946,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 174041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 174141,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 174420,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 174668,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 175042,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 175044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 175335,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 175745,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 176041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 176196,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 176507,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 176818,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 176991,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 177041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 177249,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 177405,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 177555,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 178042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 178083,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 178645,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 178797,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 179041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 179046,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 179168,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 179283,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 179434,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 179572,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 180000,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 180041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 180259,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 180570,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 180895,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 181041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 181071,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 181674,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 181931,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 182041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 182490,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 182840,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 182995,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 183041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 183159,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 183408,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 183790,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 184041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 184116,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 184265,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 184520,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 184802,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 184958,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 185040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 185110,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 185341,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 185550,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 185851,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 186041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 186213,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 186425,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 186540,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 186742,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 187023,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 187040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 187458,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 188041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 188146,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 188578,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 188782,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 188934,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 189041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 189112,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 189271,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 189618,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 190041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 190077,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 190982,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 191040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 191137,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 191351,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 191523,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 191666,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 191855,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 192040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 192436,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 192798,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 192932,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 193041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 193255,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 193541,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 194041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 194285,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 194661,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 194819,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 195041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 195092,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 195297,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 195556,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 195807,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 195961,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 196040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 196146,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 196279,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 196569,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 196908,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 197040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 197285,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 197459,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 197613,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 197757,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 197978,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 198040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 198200,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 198419,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 198787,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 199041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 199205,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 199333,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 199459,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 199591,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 199701,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 199824,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 200041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 200075,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 200380,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 200763,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 200948,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 201041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 201258,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 202041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 202191,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 202456,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 202812,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 203041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 203264,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 203558,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 203689,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 203843,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 204041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 204219,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 204417,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 204907,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 205041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 205282,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 205464,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 205871,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 206040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 206277,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 206660,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 206803,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 206947,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 207041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 207139,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 207353,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 207664,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 208041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 208161,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 208412,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 208580,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 208870,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 209040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 209070,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 209281,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 209513,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 209812,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 210040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 210211,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 210452,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 210589,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 210746,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 210886,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 211041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 211122,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 211866,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 212004,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 212040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 212169,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 212487,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 213040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 213450,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 213932,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 214041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 214233,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 214440,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 214730,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 215040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 215349,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 215825,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 216041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 217041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 217833,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 218026,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 218040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 218499,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 218939,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 219040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 219248,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 219607,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 219752,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 219999,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 220040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 220545,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 220858,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 221041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 221235,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 221406,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 221583,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 221833,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 222040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 222263,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 222565,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 222740,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 222919,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 223040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 223069,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 223308,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 223604,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 223743,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 224041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 224087,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 224865,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 225033,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 225040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 225200,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 225500,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 225668,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 225818,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 226040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 226070,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 226307,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 226702,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 227040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 227057,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 227221,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 227561,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 228041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 228243,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 228558,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 228703,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 228870,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 229024,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 229040,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 229299,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 229452,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 229639,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 229809,
            "action": {
                "type": "hardDrop"
            }
        }
    ]
}

export const devGameC:ActionHistory = 
{
    "actions": [
        {
            "timestamp": 565,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 1044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 1516,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 1755,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 1895,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 2043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 2182,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 2610,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 2801,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 2979,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 3043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 3237,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 3404,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 3852,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 4043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 4124,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 4370,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 4542,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 4764,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 5043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 5130,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 5419,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 5574,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 5722,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 6044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 6161,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 6299,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 6443,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 7035,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 7042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 7780,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 7914,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 8182,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8319,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8450,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8583,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8768,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 8900,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9015,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 9244,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9396,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9466,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 9763,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 10043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 10047,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 10249,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 10435,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 10640,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 10830,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 11040,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 11043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 11244,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 11442,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 11619,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 12044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 12454,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 12726,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 12940,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 13093,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13237,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13370,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13525,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13677,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 13843,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14016,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 14285,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14433,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14611,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14775,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 14979,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 15043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 15171,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 15387,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 16043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 16549,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16703,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 16919,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 17043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 17403,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 17667,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 18043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 18251,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 18656,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 18919,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 19086,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19210,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19347,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19487,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19616,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19753,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 19938,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 20043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 20216,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 20439,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 20828,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 21043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 21335,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 21556,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 22043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 22257,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 22603,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 22774,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 23043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 23069,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 24022,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 24043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 24570,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 24769,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 24994,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 25043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 25662,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 26043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 27043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 27182,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 28043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 28655,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 28857,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29015,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 29165,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29306,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29487,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 29872,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 30043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 30294,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 30821,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 30979,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 31124,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31274,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31448,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31569,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31715,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 31973,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 32043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 32198,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 32395,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 32613,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 32774,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 32947,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 33044,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 33184,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 33521,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 33680,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 33793,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 33918,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 34043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 34301,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 34930,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 35043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 35071,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 35431,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 35992,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 36042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 36520,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 36793,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 37002,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 37180,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37320,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37495,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37677,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 37859,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 38022,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 38043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 38185,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 38315,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 38548,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 38808,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 39043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 39992,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 40043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 40451,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 40697,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 41043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 41078,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 41698,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 42043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 42129,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 42353,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 42545,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 42688,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 42854,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 43043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 43483,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 44043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 44745,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 45003,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 45042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 45232,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 45556,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 45761,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 46043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 46407,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 47043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 47229,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 47429,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 47606,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 47729,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 47864,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 48043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 48104,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 49043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 49537,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 49816,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 49953,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 50043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 50112,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 50190,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 50336,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 50439,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 50816,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 51043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 51761,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 52043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 52240,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 52439,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 52593,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 52951,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 53043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 53385,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 53571,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 53715,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 53850,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 54002,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 54042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 54213,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 54784,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 55043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 55274,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 55441,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 55603,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 55774,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 55924,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 56042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 56107,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 56557,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 56929,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 57042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 57203,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 57451,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 57650,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 57788,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 58042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 58058,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 58199,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 58354,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 58506,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 58717,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 58850,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 59016,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 59043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 59165,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 59294,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 59918,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 60043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 60588,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 60752,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 60937,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 61042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 61197,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 61393,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 61602,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 62019,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 62042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 62444,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 63043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 63288,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 63427,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 63555,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 63707,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 64043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 64049,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 64222,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 64388,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 64525,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 64764,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 65043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 65257,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65413,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65531,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65627,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 65772,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 66042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 66121,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 66559,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 66728,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 66929,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 67042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 67199,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 67570,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 67932,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 68042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 68097,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 68246,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 68399,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 68552,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 68737,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 69043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 69358,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 69920,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 70043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 70082,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 70455,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 70958,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 71042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 71921,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 72043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 72455,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 72617,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 73025,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 73042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 73207,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 73400,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 73870,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 74042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 74168,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 74463,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 74885,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 75311,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75442,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75524,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 75659,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 76042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 76167,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 76454,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 76713,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 77042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 77184,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 77367,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 77511,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 77646,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 77776,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 78042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 78085,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 78468,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 79043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 79216,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 79593,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 79741,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 79881,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 80030,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 80042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 80156,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 80569,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 81042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 81314,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 81540,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 82041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 82220,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 82441,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 82588,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 82699,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 82812,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 83042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 83350,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 83727,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 84014,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 84042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 84204,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 84601,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 84865,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 85042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 85146,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 85292,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 85424,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 85703,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 85922,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 86042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 86197,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 86548,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 86700,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 87042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 87179,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 87331,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 87475,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 87942,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 88042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 88424,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 88733,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 88944,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 89042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 89139,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 89361,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 89507,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 89645,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 89791,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 90043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 90055,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 90271,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 90556,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 91043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 91046,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 91315,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 91621,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 91992,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 92043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 92136,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 92357,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 92744,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 92902,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 93042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 93085,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 94043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 94278,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 94440,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 94551,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 95042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 95064,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 95444,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 95604,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 95840,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 96043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 96054,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 96213,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 96346,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 96716,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 97042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 97689,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 97823,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 97982,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 98042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 98232,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 98557,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 98784,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 99043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 99359,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 99541,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 99716,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 99854,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 100041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 100072,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 100504,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 100969,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 101042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 101462,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 101606,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 101756,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 101989,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 102043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 102274,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 102409,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 102551,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 102655,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 102782,
            "action": {
                "type": "move",
                "direction": "down"
            }
        },
        {
            "timestamp": 103042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 103093,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 103495,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 103992,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 104041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 104101,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104271,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104397,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104509,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104625,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 104855,
            "action": {
                "type": "rotate",
                "direction": "counterclockwise"
            }
        },
        {
            "timestamp": 105042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 105077,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 105494,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 105830,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 106042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 106085,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 106266,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 106559,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 106733,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 106894,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 107042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 107047,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 107379,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 107876,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 108043,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 108580,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 109042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 109046,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109220,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109380,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109530,
            "action": {
                "type": "move",
                "direction": "right"
            }
        },
        {
            "timestamp": 109820,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 110042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 110308,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 110439,
            "action": {
                "type": "move",
                "direction": "left"
            }
        },
        {
            "timestamp": 110664,
            "action": {
                "type": "rotate",
                "direction": "clockwise"
            }
        },
        {
            "timestamp": 111042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 111045,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 111725,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 111940,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 112042,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 112162,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 112381,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 112576,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 112806,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113015,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113041,
            "action": {
                "type": "gameStep"
            }
        },
        {
            "timestamp": 113224,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113422,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113625,
            "action": {
                "type": "hardDrop"
            }
        },
        {
            "timestamp": 113846,
            "action": {
                "type": "hardDrop"
            }
        }
    ]
}
