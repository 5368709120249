import React, { useRef, useEffect, Context } from "react";
import styled from "styled-components";

import * as Logic from "../core/logic.ts";

interface Props {
  blockData: Logic.BlockData;
  blockSize?: number;
}

const outlineColor: string = "#000";

const colorMap = {
	1 : '#777',    // 1 is only used for development
	2 : '#005FBE', // production colors start here
	3 : '#E8E200',
	4 : '#69008C',
	5 : '#A2ECBA',
	6 : '#28325A',
	7 : '#FBA700',
	8 : '#175641',
	9 : '#F6E67D'
}

export const BlockDataDisplay = (props: Props) => {
  const blockSize: number = props.blockSize ?? 32;
  const boardHeight = Logic.blockHeight(props.blockData);
  const canvasWidth = props.blockData.width * blockSize + 2;
  const canvasHeight = boardHeight * blockSize + 2;

  const canvas = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const ctx = canvas.current!.getContext("2d") as CanvasRenderingContext2D;

    ctx.strokeStyle = outlineColor;
    ctx.lineWidth = 2;

    ctx.clearRect(0, 0, canvas.current!.width, canvas.current!.height);

    for (let i = 0; i < props.blockData.data.length; i++) {
      const value = props.blockData.data[i];
      if (value > 0) {
        const data = props.blockData.data;
        const width = props.blockData.width;
        const { x, y } = Logic.positionFromIndex(i, width);
        const dx = x * blockSize + 1;
        const dy = y * blockSize + 1;

        ctx.fillStyle = colorMap[value];
        ctx.fillRect(dx, dy, blockSize, blockSize);
        ctx.strokeRect(dx, dy, blockSize, blockSize);
      }
    }
  }, [props]);

  return <Board ref={canvas} width={canvasWidth} height={canvasHeight}></Board>;
};

const Board = styled.canvas`
	background: #fff;
	image-rendering: pixelated;
	height: 100%;
	display: block;
`