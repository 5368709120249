import React, { useEffect, useState, useRef } from "react";
import { SingleplayerGame } from "./SingleplayerGame.tsx";

import { eighteenBoard } from "./dev/dev-data.ts";

export default function App({ user, nickname, mobile }) {
  return (
    <>
      <SingleplayerGame
        board={eighteenBoard}
        nickname={nickname}
        controlsLayout={mobile ? "mobile" : "desktop"}
        onGameOver={() => {
          setTimeout(() => {
            Turbo.visit("/");
          }, 3000);
        }}
        onExitButton={() => {
          Turbo.visit("/");
        }}
      />
    </>
  );
}
